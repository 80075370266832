<template>
  <!-- Modal -->
  <div
    class="modal"
    id="searchModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <form>
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Búsqueda avanzada de Menú por Perfil</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group row">
              <label class="col-md-4 col-form-label">Tipo de Usuario:</label>
              <select v-model="item.tus_id" class="form-control col-md-8">
                <option v-for="tpousu in tpousus" v-bind:value="tpousu._id">{{ tpousu.tus_nmbre }}</option>
              </select>
            </div>
            <div class="form-group row">
              <label class="col-md-4 col-form-label">Menú:</label>
              <select v-model="item.men_id" class="form-control col-md-8">
                <option v-for="menu in menus" v-bind:value="menu._id">{{ menu.men_descripcion }}</option>
              </select>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
            <button
              type="submit"
              class="btn btn-primary"
              data-dismiss="modal"
              v-on:click="$emit('search', item)"
            >Buscar</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  props: ["item"],
  data() {
    return {
      tpousus: [],
      menus: []
    };
  },
  mounted: function() {},
  created: function() {
    this.fetchTpousu();
    this.fetchMenu();
  },
  methods: {
    fetchTpousu() {
      let uri = "/tpousus";
      this.axios.get(uri).then(response => {
        this.tpousus = response.data;
      });
    },
    fetchMenu() {
      let uri = "/menus";
      this.axios.get(uri).then(response => {
        this.menus = response.data;
      });
    }
  } // END METHODS
};
</script>

<style>
/*
.modal-dialog {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 500px;
  height: 300px;
}
*/
</style>
